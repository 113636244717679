<template>
  <div class="container">

    <!-- 搜索框 -->
    <div class="center content-inputs" style="justify-content: right;" v-if="posts.length">
      <vs-input
        success
        v-model="title"
        :placeholder="placeholder"
        icon-after
        @keyup.enter.native="getPosts"
      >
        <template #icon>
          <i class='bx bx-search-alt'></i>
        </template>
      </vs-input>
    </div>
    <hr v-if="posts.length"/>

    <div v-if="posts.length">
      <!-- 列表 -->
      <div class="grid-3_xs-1_sm-2_md-2" v-if="posts.length">
        <div
          :key="index"
          v-for="(post, index) in posts"
          class="col"
        >
          <PostCard :that="that" :post="post" class="center" />
        </div>
      </div>
      <!-- 分页 -->
      <div class="center con-pagination">
        <vs-pagination v-model="currentPage" :length="Math.ceil(totalPostCount / 6)" not-margin progress />
      </div>
    </div>
    <Nothing v-else />

    <!-- 加载动画 -->
    <div
      :class="{ hasOpenLoading }"
       class="center">
    </div>

  </div>
</template>

<script>
import PostCard from '@/components/PostCard.vue'
import Nothing from '@/components/Nothing.vue'
export default {
  name: 'ViewPosts',
  components: {
    PostCard,
    Nothing
  },
  data: function () {
    return {
      posts: [],
      totalPostCount: 0,

      currentPage: 1,
      pageSize: 6,

      title: '',
      placeholder: '',

      hasOpenLoading: false,

      that: this
    }
  },
  watch: {
    currentPage (newValue, oldValue) {
      this.getPosts()
    }
  },
  methods: {
    getPosts () {
      this.request
        .GET('/post/getPosts', {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          title: this.title
        })
        .then((res) => {
          this.hasOpenLoading = false
          this.posts = res.data
          this.totalPostCount = res.data[0].total_post_count
          this.placeholder = 'search from post' + (this.totalPostCount >= 2 ? 's(' : '(') + res.data[0].total_post_count + ')'
        })
    }
  },
  created () {
    this.changeTitle('Posts')

    this.hasOpenLoading = true
    this.getPosts()
  }
}
</script>
